var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("table-to-storage-collection-listing"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }